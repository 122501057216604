<template>
  <div>
    <div class="p-8">
      <Brand />
    </div>

    <div class="flex p-8">
      <article class="mx-auto prose text-justify prose-l">
        <h1>Legal notice</h1>

        <h2>Disclosures according to § 5 TMG</h2>
        <p>
          Leon Husmann<br />
          Wolbecker Straße 106<br />
          48155 Münster
        </p>

        <h2>Contact</h2>
        <p>
          Telefon: +49 (0) 178 89 40 211<br />
          E-Mail: legal@leonhusmann.com
        </p>
      </article>
    </div>

    <div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Brand from "../components/Brand.vue";
import Footer from "../components/Footer.vue";

export default {
  components: {
    Brand,
    Footer,
  },
};
</script>
